import React, { createContext, useEffect, useState, useContext, useCallback, useRef } from 'react';
import Wkt from 'wicket';
import L from 'leaflet';
import { MIN_ZOOM_LEVEL, STARTING_AACHEN, luetticherData } from '../components/ipkw/IPKWConsts'; // Import luetticherData
import { getPolygonArea, isPointInPolygon } from '../components/ipkw/IPKWUtils';


const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedLegend, setSelectedLegend] = useState('specificLegend');
  const [leafletState, setLeafletState] = useState({
    zoom: MIN_ZOOM_LEVEL,
    bounds: L.latLngBounds(L.latLng(STARTING_AACHEN), L.latLng(STARTING_AACHEN))
  });
  const [mapState, setMapState] = useState({
    geometry: luetticherData.geometry,
    loadedGeometry: null,
  });

  const [mapHoverState, setMapHoverState] = useState({
    currentHoverID: null,
    hoverData: null,
    x: 0,
    y: 0,
    hoverEnabled: false,
  });

  const [sidebarHoverState, setSidebarHoverState] = useState({
    resultsHover: { year: null, type: null },
    buildingHover: { year: null, type: null },
  });

  const [currentGeoJSONData, setCurrentGeoJSONData] = useState(null);
  const [sidebarState, setSidebarState] = useState('buildingTypes');

  const [quartierSelection, setQuartierSelection] = useState({
    quartierSelection: 'luetticher',
    modellQuartierOptions: [
      { value: 'luetticher', label: 'Lütticher Straße' },
      { value: 'preuswald', label: 'Preuswald' },
      { value: 'ziegelweiher', label: 'Am Ziegelweiher' },
      { value: 'load', label: '🖥️ Quartier laden' },
    ],
  });
  const [ipkwQuartierData, setIpkwQuartierData] = useState({
    buildingData: [],
    quartierData: {
      nutzfläche: 0,
      grundfläche: 0,
      gesamtfläche: 0,
    }
  });

  const [solutionBuilderData, setSolutionBuilderData] = useState({
    componentsList: [],
    baseScenario: 'Grün',
    activeEvents: [],
  });

  const fetchDataCalled = useRef(false);


  useEffect(() => {
    if (!currentGeoJSONData) {
      setIpkwQuartierData(prevState => ({ ...prevState, buildingData: [] }));
    }

    if (currentGeoJSONData) {
      let overallHeatDemand = 0.0;
      let buildingData = {};

      let gesamtNutzfläche = 0;
      let gesamtGrundfläche = 0;

      currentGeoJSONData.forEach(feature => {
        const { gebaeudetyp, rw_ww } = feature.properties;

        if (gebaeudetyp && rw_ww) {
          gesamtNutzfläche += feature.properties.nutzflaeche;
          gesamtGrundfläche += feature.properties.shape_area;
          overallHeatDemand += rw_ww;

          if (!buildingData[gebaeudetyp]) {
            buildingData[gebaeudetyp] = { gebaeudetyp: gebaeudetyp, heatDemand: 0, count: 0 };
          }
          buildingData[gebaeudetyp].heatDemand += rw_ww;
          buildingData[gebaeudetyp].count += 1;
        }
      });

      let sortedBuildingData = Object.entries(buildingData).map(([type, data]) => ({
        type,
        ...data,
        percentage: (data.heatDemand / overallHeatDemand) * 100
      })).sort((a, b) => b.percentage - a.percentage);

      let blocks = 10;
      let blockAllocation = [];

      for (const entry of sortedBuildingData) {
        if (blocks === 0) break;
        let block = Math.ceil(entry.percentage / 10);
        if (block > 0) {
          const gebaeudetyp = entry.gebaeudetyp;
          const split = gebaeudetyp.split('_');
          const gebaeudetypString = split[0];
          const yearString = split[1];
          blockAllocation.push({
            year: Number.parseInt(yearString),
            type: gebaeudetypString,
            blocks: block,
            percentage: entry.percentage,
          });
        }
        blocks -= block;
      }

      blockAllocation = blockAllocation.reduce((acc, curr) => {
        let existing = acc.find(item => item.type === 'EFH' && item.year === curr.year);
        if (curr.type === 'RH') {
          if (existing) {
            existing.blocks += curr.blocks;
          } else {
            acc.push({ ...curr, type: 'EFH' });
          }
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);

      const years = [1945, 1980, 2000, 2010, 2020];
      blockAllocation = blockAllocation.map(item => {
        let nearest = years.find(year => year >= item.year);
        if (nearest) {
          item.year = nearest;
        } else {
          item.year = 2020;
        }
        return item;
      });

      let combinedData = [];
      blockAllocation.forEach(item => {
        let existing = combinedData.find(data => data.year === item.year && data.type === item.type);
        if (existing) {
          existing.blocks += item.blocks;
        } else {
          combinedData.push(item);
        }
      });

      const polygon = mapState.geometry.map(latlng => [latlng.lng, latlng.lat]);
      const gesamtfläche = getPolygonArea(polygon);

      setIpkwQuartierData(prevState => ({
        ...prevState,
        buildingData: combinedData,
        quartierData: {
          nutzfläche: gesamtNutzfläche,
          grundfläche: gesamtGrundfläche,
          gesamtfläche: gesamtfläche
        }
      }));

    }
  }, [currentGeoJSONData, mapState.geometry]);


  const fetchData = useCallback(() => {
    if (mapState.geometry) {

      const url = new URL('https://monitor.nowum.fh-aachen.de/oeds/rpc/kwp_nrw_by_bbox');

      const geom = mapState.geometry;
      const bounds = geom.reduce((acc, curr) => {
        acc[0] = Math.min(acc[0], curr.lat);
        acc[1] = Math.min(acc[1], curr.lng);
        acc[2] = Math.max(acc[2], curr.lat);
        acc[3] = Math.max(acc[3], curr.lng);
        return acc;
      }, [Infinity, Infinity, -Infinity, -Infinity]);
      const swlng = bounds[0];
      const swlat = bounds[1];
      const nelng = bounds[2];
      const nelat = bounds[3];
      url.searchParams.append('in_xmin', swlat);
      url.searchParams.append('in_ymin', swlng);
      url.searchParams.append('in_xmax', nelat);
      url.searchParams.append('in_ymax', nelng);
      var wkt = new Wkt.Wkt();

      fetch(url)
        .then(response => response.text())
        .then(data => {
          let db_json = JSON.parse(data);
          db_json = db_json.map((item) => {
            wkt.read(item.geometry);
            const geoJsonGeometry = wkt.toJson();
            return {
              type: "Feature",
              geometry: geoJsonGeometry,
              properties: {
                fest_id: item.fest_id,
                gemeinde: item.gemeinde,
                strasse: item.strasse,
                hausnr: item.hausnr,
                nutzung: item.nutzung,
                gebaeudetyp: item.gebaeudetyp,
                sanierungsstand: item.sanierungsstand,
                stockwerke: item.stkw,
                nutzflaeche: Number.parseFloat(item.nutzflaeche),
                shape_area: Number.parseFloat(item.shape_area),
                rw_ww_spez: Number.parseFloat(item.rw_ww_spez),
                rw_ww: Number.parseFloat(item.rw_ww),
              },
            };
          });
          if (mapState.geometry) {
            setCurrentGeoJSONData(filterFeatures(mapState.geometry, db_json));
          }
        })
        .catch(error => {
          console.error("Network Error:", error);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  }, [mapState]);



  function filterFeatures(layer, data) {
    const polygon = layer.map(latlng => [latlng.lng, latlng.lat]);

    const features = data.filter(feature => {
      const latLng = [feature.geometry.coordinates[0][0][0][1], feature.geometry.coordinates[0][0][1][0]];

      return isPointInPolygon(latLng, polygon);
    });

    return features;
  }


  useEffect(() => {
    fetchData();
    fetchDataCalled.current = true;
  }, [mapState, fetchData]);




  return (
    <DashboardContext.Provider value={{
      selectedLegend, setSelectedLegend,
      leafletState, setLeafletState,
      currentGeoJSONData, setCurrentGeoJSONData,
      ipkwQuartierData, setIpkwQuartierData,
      mapState, setMapState,
      mapHoverState, setMapHoverState,
      sidebarHoverState, setSidebarHoverState,
      sidebarState, setSidebarState,
      quartierSelection, setQuartierSelection,
      solutionBuilderData, setSolutionBuilderData,
    }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
