import React from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const Co2Chart = () => {

    const dataNullszenario = [
        { year: 2025, nullSzenario: 70, solutionBuilder: 120 },
        { year: 2030, nullSzenario: 70, solutionBuilder: 100 },
        { year: 2035, nullSzenario: 70, solutionBuilder: 80 },
        { year: 2040, nullSzenario: 70, solutionBuilder: 40 },
        { year: 2045, nullSzenario: 60, solutionBuilder: 20 },
        { year: 2050, nullSzenario: 50, solutionBuilder: 10 },
    ];
    

    return (


                <div className='status-quo-charts'>
                    <div className='chart-container'>
                        <div className='chart-label'>GT Co2/a</div>
                        <ResponsiveContainer width='100%' height='100%'>
                            <LineChart data={dataNullszenario}>
                                <Legend />
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis dataKey='year' />
                                <YAxis />
                                <Tooltip />
                                <Line name='Vergleichslösung'  type='monotone' dataKey='nullSzenario' stroke='#ff3300' />
                                <Line name='Wärmelösung' type='monotone' dataKey='solutionBuilder' stroke='#8884d8' />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </div>

    );
};
export default Co2Chart;
