import React from 'react';
import AboutPage from '../components/home/AboutPageNav';
import WikiPage from '../components/home/WikiPageNav';
import IpkwPage from '../components/home/IpkwPageNav';
import '../styles/home/CardStyles.css'; 

const HomePage = () => {
  return (
    <div>
      <h1>Home Page</h1>
      <div className="card-container">
        <AboutPage />
        <WikiPage />
        <IpkwPage />
      </div>
    </div>
  );
}

export default HomePage;
