import React from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';

const QuartierTypes = () => {
  const { ipkwQuartierData } = useDashboardContext();
  const {  quartierData } = ipkwQuartierData;


  const { nutzfläche, grundfläche, gesamtfläche } = quartierData;

  const GFZ = gesamtfläche ? (nutzfläche / gesamtfläche).toFixed(2) : 'N/A';

  return (
    <div className='quartier-sidebar-info-card'>
      <h2>Quartierauswahl Überblick</h2>

      <h3>Flächenübersicht</h3>
      <ul>
        <li>Quartier Fläche: {gesamtfläche.toFixed(2)} m²</li>
        <li>Grundfläche: {grundfläche.toFixed(2)} m²</li>
        <li>Beheizte Fläche: {nutzfläche.toFixed(2)} m²</li>
        <li>Beheizte Fläche / Quartier Fläche: {GFZ}</li>
      </ul>
    </div>
  );
};

export default QuartierTypes;
