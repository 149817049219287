import React, { memo } from 'react';
import { eekMapping } from '../IPKWConsts';

const HoverCard = memo(({mapHoverState, setMapHoverState, mousePosition }) => {

  if (!mapHoverState.hoverEnabled) return null;
  if (!mapHoverState.hoverData) return null;
  const eek = eekMapping.find(eek => eek.sanierungstand === mapHoverState.hoverData.sanierungsstand)?.eek;

  if (!mapHoverState.currentHoverID ||mapHoverState.currentHoverID !==mapHoverState.hoverData.fest_id) {
    setMapHoverState(prevState => ({ ...prevState, currentHoverID:mapHoverState.hoverData.fest_id, x: mousePosition.x, y: mousePosition.y }));
    return (
      <div className="hover-card" style={{
        left: `${mousePosition.x}px`,
        top: `${mousePosition.y}px`,

      }}>
        <div className="attribute"><strong>Gebäude Typ:</strong> <br /> {mapHoverState.hoverData.gebaeudetyp}</div>
        <div className="attribute"><strong>Gebäude Nutzung:</strong><br /> {mapHoverState.hoverData.nutzung}</div>
        <div className="attribute"><strong>Nutzfläche:</strong> {mapHoverState.hoverData.nutzflaeche.toFixed(2)} m²</div>
        <div className="attribute"><strong>Stockwerke:</strong> {mapHoverState.hoverData.stockwerke}</div>
        <div className="attribute"><strong>Spezifischer Raum & Wasserwärmebedarf:</strong> {mapHoverState.hoverData.rw_ww_spez.toFixed(2)} kWh/m²a</div>
        <div className="attribute"><strong>Jahres Raum & Wasserwärmebedarf:</strong> {mapHoverState.hoverData.rw_ww.toFixed(2)} kWh/a</div>
        <div className="attribute"><strong>Sanierungsstand:</strong> {eek}</div>
      </div>
    );
  }

  if (mapHoverState.currentHoverID === mapHoverState.hoverData.fest_id) {
    return (
      <div className="hover-card" style={{
        left: `${mapHoverState.x}px`,
        top: `${mapHoverState.y}px`,
      }}>
        <div className="attribute"><strong>Gebäude Typ:</strong> <br /> {mapHoverState.hoverData.gebaeudetyp}</div>
        <div className="attribute"><strong>Gebäude Nutzung:</strong><br /> {mapHoverState.hoverData.nutzung}</div>
        <div className="attribute"><strong>Nutzfläche:</strong> {mapHoverState.hoverData.nutzflaeche.toFixed(2)} m²</div>
        <div className="attribute"><strong>Stockwerke:</strong> {mapHoverState.hoverData.stockwerke}</div>
        <div className="attribute"><strong>Spezifischer Raum & Wasserwärmebedarf:</strong> {mapHoverState.hoverData.rw_ww_spez.toFixed(2)} kWh/m²a</div>
        <div className="attribute"><strong>Jahres Raum & Wasserwärmebedarf:</strong> {mapHoverState.hoverData.rw_ww.toFixed(2)} kWh/a</div>
        <div className="attribute"><strong>Sanierungsstand:</strong> {eek}</div>
      </div>
    );
  }

  return null
});

export default HoverCard;