import React from 'react';

const WaermepumpePage = () => {
  return (
    <div>
      <h1>Wärmepumpe</h1>
      <p>This is a page about Wärmepumpe technologies.</p>
    </div>
  );
}

export default WaermepumpePage;
