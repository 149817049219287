import React, { useState } from 'react';
import SolutionCard from './SolutionCard';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/SolutionBuilder.css';
import { useDashboardContext } from '../../../../context/DashboardContext';

const SolutionBuilder = () => {
  const { currentGeoJSONData } = useDashboardContext();

  const [solutions, setSolutions] = useState([
    { id: 0, name: 'Komponente A', percentage: 0 },
  ]);

  const maxTotalPercentage = 100;

  const handleAddSolution = () => {
    setSolutions((prevSolutions) => {
      const newId = prevSolutions.length;
      const newSolution = {
        id: newId,
        name: `Komponente ${String.fromCharCode(65 + newId)}`,
        percentage: 0,
      };
      return [...prevSolutions, newSolution];
    });
  };

  const handlePercentageChange = (id, value) => {
    const otherTotal = solutions.reduce((acc, solution) => {
      if (solution.id !== id) {
        return acc + solution.percentage;
      }
      return acc;
    }, 0);

    const newPercentage = Math.min(value, maxTotalPercentage - otherTotal);

    setSolutions((prevSolutions) =>
      prevSolutions.map((solution) =>
        solution.id === id ? { ...solution, percentage: newPercentage } : solution
      )
    );
  };

  const handleDeleteSolution = (id) => {
    setSolutions((prevSolutions) => prevSolutions.filter((solution) => solution.id !== id));
  };

  const totalPercentage = solutions.reduce((acc, solution) => acc + solution.percentage, 0);

  const absoluteValue = currentGeoJSONData? currentGeoJSONData.reduce((acc, feature) => acc + feature.properties.rw_ww, 0): 0;
  const currentValue = totalPercentage*absoluteValue/100;

  const isCoverageComplete = totalPercentage >= maxTotalPercentage;

  const formatEnergyValue = (valueInKWh) => {
    if (valueInKWh >= 1e6) {
      return `${(valueInKWh / 1e6).toFixed(2)} GWh`;
    } else if (valueInKWh >= 1e3) {
      return `${(valueInKWh / 1e3).toFixed(2)} MWh`;
    } else {
      return `${valueInKWh.toFixed(2)} kWh`;
    }
  };

  return (
    <div className='solution-builder-container'>
      <div className={`coverage-display ${isCoverageComplete ? '' : 'incomplete'}`}>
        <div className='solution-builder-titles'>
          <p>Eigene Wärmelösung:</p>

          <p className='deckung-text'>
            Deckung: {totalPercentage}% ({formatEnergyValue(currentValue)} / {formatEnergyValue(absoluteValue)})
          </p>
        </div>
      </div>
      <div className='solution-cards-container'>
        {solutions.map((solution) => (
          <div key={solution.id} className='solution-card'>
            <div className='card-header'>
              <h4>{solution.name}</h4>
              <input
                type='range'
                min='0'
                max='100'
                value={solution.percentage}
                onChange={(e) => handlePercentageChange(solution.id, parseInt(e.target.value))}
                className='percentage-slider'
              />
              <span>{solution.percentage}%</span>
              <button onClick={() => handleDeleteSolution(solution.id)} className='delete-button'>
                X
              </button>
            </div>
            <SolutionCard />
          </div>
        ))}
        <button className='expand-button' onClick={handleAddSolution}>
          +
        </button>
      </div>
    </div>
  );
};
export default SolutionBuilder;
