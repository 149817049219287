import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';

import flut from '../../../../images/flut.png';
import hack from '../../../../images/energieversorger_hack.png';
import ford_ee from '../../../../images/fordEE.png';
import krieg from '../../../../images/krieg.png';
import random from '../../../../images/random.png';

import { useDashboardContext } from '../../../../context/DashboardContext';


const ScenarioSelector = () => {
    const { solutionBuilderData, setSolutionBuilderData } = useDashboardContext();


    const [activeScenarios, setActiveScenarios] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    const images = [
        { src: flut, name: 'Hochwasser' },
        { src: hack, name: 'Energieversorger Hack' },
        { src: ford_ee, name: 'Neue Förderung für Erneuerbare Energien' },
        { src: krieg, name: 'Gaspreis Steigt' },
        { src: random, name: 'Zufall' },
    ];
    const handleCostScenarioClick = (scenario) => {
        setSolutionBuilderData( prevData => ({...prevData, baseScenario: scenario}));
    };
    const toggleScenario = (name) => {
        if (activeScenarios.includes(name)) {
            setActiveScenarios(activeScenarios.filter(scenario => scenario !== name));
        } else {
            setActiveScenarios([...activeScenarios, name]);
        }
    };

    const handleRandomScenario = () => {
        // Randomly toggle a scenario
        const randomIndex = Math.floor(Math.random() * (images.length - 1));
        toggleScenario(images[randomIndex].name);
    };
    const handleImageClick = () => {
        setShowOptions(!showOptions);
    };

    return (
        <div className='scenario-selector'>
            <div className='base-szenario-stack'>
                <div className='scenario-selector-title'>
                    Basis Szenarien
                </div>
                <div className='cost-scenarios'>
                    {["Grün", "Grau", "Braun"].map((scenario) => (
                        <button
                            key={scenario}
                            className={solutionBuilderData.baseScenario === scenario ? 'active' : ''}
                            onClick={() => handleCostScenarioClick(scenario)}
                        >
                            {scenario}
                        </button>
                    ))}
                </div>
            </div>
            <div className='szenario-event-row'>
                <div className='card-selector'>
                    <h3 className='selector-title' onClick={handleImageClick}
                    >Event Karten</h3>
                    <div className='square-image-container' >

                        {showOptions && (
                            <div className='image-options-popup-left'>
                                {images.map((img, index) => (
                                    <div key={index} className='option-item' onClick={() => img.name === 'Zufall' ? handleRandomScenario() : toggleScenario(img.name)}>
                                        <img
                                            src={img.src}
                                            alt={img.name}
                                            className={activeScenarios.includes(img.name) ? 'option-image active' : 'option-image'}
                                        />
                                        <div className='option-name'>{img.name}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                </div>

                <div className='active-scenarios-list'>
                    {activeScenarios.map((scenario, index) => (
                        <div className='scenario-entry' key={index}>{scenario}</div>
                    ))}
                </div>

            </div>
        </div>

    );
};
export default ScenarioSelector;
