import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const WikiHomePage = () => {
    const location = useLocation();


    return (
        <div>
                  { location.pathname === '/wiki' ? <h1>Wiki Home</h1> : null }

            
            <Outlet />
        </div>
    );
}

export default WikiHomePage;
