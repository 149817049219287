// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;
}

.card {
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: var(--white);
    cursor: pointer;
}

.card:hover {
    transform: scale(1.05);
}

.card-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

p {
    color: #666;
    font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/styles/home/CardStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,sCAAsC;IACtC,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB","sourcesContent":[".card-container {\n    display: flex;\n    justify-content: space-around;\n    padding: 20px;\n    gap: 20px;\n}\n\n.card {\n    width: 250px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 20px;\n    text-align: center;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease-in-out;\n    background-color: var(--white);\n    cursor: pointer;\n}\n\n.card:hover {\n    transform: scale(1.05);\n}\n\n.card-icon {\n    width: 80px;\n    height: 80px;\n    margin-bottom: 15px;\n}\n\nh2 {\n    font-size: 1.5em;\n    margin-bottom: 10px;\n}\n\np {\n    color: #666;\n    font-size: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
