import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';

import WikiHomePage from '../pages/WikiHomePage';
import GEGPage from '../pages/wiki_pages/GEGPage';
import WaermepumpePage from '../pages/wiki_pages/WaermepumpePage';
import SanierungPage from '../pages/wiki_pages/SanierungPage';
import SzenarienPage from '../pages/wiki_pages/SzenarienPage';

import IPKWHomePage from '../pages/IPKWHomePage';
import QuartierPage from '../pages/ipkw_pages/QuartierPage';
import DashboardPage from '../pages/ipkw_pages/DashboardPage';

const AppRoutes = () => {
  return (

      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/about" element={<AboutPage />} />

        <Route path="/wiki" element={<WikiHomePage />}>
          <Route path="geg" element={<GEGPage />} />
          <Route path="waermepumpe" element={<WaermepumpePage />} />
          <Route path="sanieren" element={<SanierungPage />} />
          <Route path="szenarien" element={<SzenarienPage />} />
        </Route>
        <Route path="/dashboard" element={<IPKWHomePage />}>
          <Route path="map" element={<QuartierPage />} />
          <Route path="quartier" element={<DashboardPage />} />
        </Route>
      </Routes>
  );
}

export default AppRoutes;
