import React from 'react';
import IPKWFoerder from '../images/IPKWFoerder.jpg';
import IPKWOne from '../images/IPKWOne.png';
import IPKWTwo from '../images/IPKWTwo.png';
import FB1 from '../images/FB1.png';
import FB4 from '../images/FB4.png';
import FHAachen from '../images/FHAachen.png';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>Über das iPKW Projekt</h1>

      <div className="content-with-images">
        <div className="text-block">
          <div className="project-info">
            <p><strong>Förderkennzeichen:</strong> 005-2302-0017</p>
            <p><strong>Projektlaufzeit:</strong> 15.05.2023 - 14.05.2025</p>
          </div>

          <h2>Ausgangssituation:</h2>
          <p>
            Wärmetransformation bedeutet ein Umdenken des bisher hauptsächlich auf nicht-erneuerbaren Ressourcen beruhenden Wärmenetzes.
            Die Planung erfordert, z.B. für Hausbesitzer, Abwägungen zwischen individuellen und kommunalen Lösungen,
            wie das Anschließen an ein kommunales Fernwärmenetz anstatt der Installation einer eigenen Heizungsanlage.
          </p>

          <h2>Das Projekt:</h2>
          <p>
            Das Forschungsprojekt iPkW entwickelt ein interaktives Toolkit, das die an der Wärmetransformation beteiligten Akteure unterstützt,
            sich kooperativ für Lösungswege zu entscheiden. Akteure – wie Kommunen, Politik, Energiekonzerne, Wohnungswirtschaft und private
            Eigentümer – mit unterschiedlichen Motivationen und Ansichten werden zu gamifizierten Workshops eingeladen. Spielelemente,
            Datenvisualisierungen der Quartiere und Zukunftsszenarien setzen die Diskussion zu Fragestellungen in Gang.
          </p>
          <p>
            Ziel ist es, Dialog und kooperatives Handeln zu fördern und dabei die Entscheidungsfindung zu erleichtern. Regelmäßige Testläufe
            des Workshopkonzepts und ein iterativer Entwicklungsprozess sind Kern des Projekts.
          </p>

          <h2>Unser Anteil am Projekt:</h2>
          <p>
            Das Projekt iPkW bringt die Expertise von drei Fachbereichen zusammen. Die Energiewirtschaft (FB10) beschäftigt sich mit technischen
            Möglichkeiten der Wärmetransformation, während Architektur und nachhaltige Quartiersentwicklung (FB1) die stadtplanerischen Aspekte
            und Akteursbedürfnisse im Blick behält. Gestaltung (FB4) ist für ein stimmiges und verständliches Gesamtbild und die übersichtliche
            Informationsvermittlung wichtig. Alle drei Fachbereiche arbeiten gemeinsam an der Konzeption des Workshops und Toolkits.
          </p>
        </div>

        <div className="image-block">
          <img src={IPKWFoerder} alt="Gefördert durch das Ministerium für Kultur und Wissenschaft des Landes Nordrhein-Westfahlen" />
          <div className="image-with-attr">
            <img src={IPKWOne} alt="Das iPKW Planspiel" />
            <div className="attr">Bildquelle: Anna-Lena Keith</div>

          </div>
          <div className="image-with-attr">
            <img src={IPKWTwo} alt="Planspiel Grafik" />
            <div className="attr">Bildquelle: Prof. Eva Vitting</div>
          </div>
        </div>
      </div>

      <div className="content-with-images">
        <h2>Projektteam</h2>
        <ul className="team-list">
          <li className="team-title">
          </li>
          <li className="team-item">
            <strong>FB Architektur 01</strong><br />
            Lehrgebiet Grundlagen des Städtebaus<br />
            Prof. Stefan Werrer<br />
            Fares Arnold Selo, B.A.
          </li>
          <li className="team-title">
          </li>
          <li className="team-item">
            <strong>FB Gestaltung 04</strong><br />
            Lehrgebiet Gestaltungslehre und angewandte Farbgestaltung<br />
            Prof. Eva Vitting<br />
            Anna-Lena Keith, B.A.

          </li>
          <li className="team-title">
          </li>
          <li className="team-item">
            <strong>FB Energietechnik 10</strong><br />
            Lehrgebiet Energiewirtschaft<br />
            Prof. Dr.-Ing. Christian Jungbluth<br />
            Dr. Sebastian Dickler<br />
            Vassily Aliseyko, M.Sc.

          </li>
        </ul>

        <div className="team-image">
          <img src={FHAachen} alt="FB Architektur Team" />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
