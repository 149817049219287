import React from 'react';
import '../../../../styles/ipkw/QuartierCard.css';
import { useDashboardContext } from '../../../../context/DashboardContext';
import { getColorBlockList } from '../../../ipkw/IPKWUtils';


const BuildingOverview = () => {
  const { ipkwQuartierData } = useDashboardContext();

  return (
    <div className='gebaeude-uebersicht-container'>
      <h3 className='inner-title'>Gebäudeübersicht</h3>
      <div className='squares-container'>
        {[...ipkwQuartierData.buildingData].map((_, index) => (
          [...Array(ipkwQuartierData.buildingData[index].blocks)].map((_, index2) => (
            <div key={index2 + " " + index}>
              <div className='square-in-square'>
                <div className='ipkw-kw-square' style={getColorBlockList(ipkwQuartierData.buildingData, index)}>
                  <div style={{ position: 'absolute', top: '2px', width: '100%', textAlign: 'center' }}>
                    {ipkwQuartierData.buildingData[index].type}
                  </div>
                  <div style={{ position: 'absolute', bottom: '2px', width: '100%', textAlign: 'center' }}>
                    {ipkwQuartierData.buildingData[index].year}
                  </div>
                </div>
              </div>
            </div>
          ))
        ))}
      </div>
    </div>
  );
};

export default BuildingOverview;
