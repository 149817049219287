import React from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';

const MoneyChart = () => {

    const dataNullszenario = [
        { year: 2025, nullSzenario: 50, solutionBuilder: 80 },
        { year: 2030, nullSzenario: 60, solutionBuilder: 80 },
        { year: 2035, nullSzenario: 70, solutionBuilder: 70 },
        { year: 2040, nullSzenario: 80, solutionBuilder: 60 },
        { year: 2045, nullSzenario: 100, solutionBuilder: 50 },
        { year: 2050, nullSzenario: 120, solutionBuilder: 50 },
    ];
    

    return (


                <div className='status-quo-charts'>
                    <div className='chart-container'>
                        <div className='chart-label'>€/a</div>
                        <ResponsiveContainer width='100%' height='100%'>
                            <LineChart data={dataNullszenario}>
                                <Legend />
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis dataKey='year' />
                                <YAxis />
                                <Tooltip />
                                <Line name='Vergleichslösung'  type='monotone' dataKey='nullSzenario' stroke='#ff3300' />
                                <Line name='Eigene Wärmelösung' type='monotone' dataKey='solutionBuilder' stroke='#8884d8' />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </div>

    );
};

export default MoneyChart;
