import React from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import ModellQuartierCard from './ModellQuartierCard';
import NullszenarioCard from './NullszenarioCard';
import Potenziale from './Potenziale';
import BuildingOverview from './BuildingOverview';

const QuartierOverview = () => {
    return (
        <div className='quartier-card-container'>
            <div className='quartier-card-top-half'>
                <ModellQuartierCard />
                <NullszenarioCard />
                <Potenziale />

            </div>
            <div className='quartier-card-lower-half'>
                <BuildingOverview />
            </div>
        </div>
    );
};

export default QuartierOverview;
